import React from 'react';

const IconLogo = () => (
	<svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(10.000000, 40.000000)">
        <g transform="translate(5.000000, 20.000000)">
        <text x="0" y="25" fill="currentColor" fontSize="30px" fontFamily="Consolas, serif">
  AP
</text>


      </g>
    </g>
  </svg>
);

export default IconLogo;
