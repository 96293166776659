import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

    }
    
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['Python','Big Data','Node.js','Machine learning','SQL','R','AWS','PySpark', 'Tableau','Postgres'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              I proudly wear the hat of an experienced and passionate Software Engineer.
              You see, I have this unwavering fascination with technology and its magical ability to tackle real-world problems,
              armed with nothing but data and some clever business logic. It's like solving puzzles with a touch of wizardry!
            </p>
            <p>
            I'm motivated by a combination of curiosity, eagerness, and a little caffeine when I take on a new project because,
            let's face it, coffee is the secret ingredient to any productive coding session.
            I take great pleasure in developing tasteful solutions that not only simplify life but also give the digital sphere a refined touch.
            </p>

            <p>
              Throughout my career, I have successfully developed scalable software solutions, conducted exploratory
              data analysis, and implemented machine learning models. I am adept at optimizing processes,
              automating complex tasks, and leveraging cloud technologies for efficient data processing.
              With strong skills in data visualization and data warehousing, I can extract valuable insights and
              generate actionable reports.
             <p>

            </p>

            </p>

            <p>
              I have hands-on experience working in Agile environments and have effectively engaged with clients,
              honing my communication and collaboration skills. This allows me to deliver high-quality solutions
              while ensuring client satisfaction and maintaining a customer-centric approach.
              I’ve also had the privilege of working in{' '}
              <a href='http://www.eastwestndt.co.in/'>manufacturing companies</a>,{' '}
              <a href="https://www.nseit.com/"> global technology firms</a> and{' '}
              <a href="https://ed.buffalo.edu/faculty-staff/it-support/labs-classrooms/data-analysis-lab.html">laboratory research with professors.</a>
            </p>
            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
